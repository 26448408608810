<template>
	<div
		:class="{
			'image-icon': fillWithPrimary,
			'custom-icon': true,
		}"
	>
		<inline-svg
			:width="sizeWidth"
			:height="sizeHeight"
			:src="require(`../assets/${file}.svg`)"
		/>
	</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
	name: 'Icon',
	components:{
		InlineSvg
	},
	props: {
		maxwidth:{
			type: [String],
			default: '100%',
			required: false,
			useDefaultForNull: true
		},
		file:{
			type: [String],
			default: 'default',
			required: true,
			useDefaultForNull: true
		},
		color: {
			type: [String],
			default: 'black',
			required: false,
			useDefaultForNull: true
		},
		fillWithPrimary: {
			type: [Boolean],
			default: false,
			required: false,
			useDefaultForNull: true
		},
		size: {
			type: [String, Number],
			default: "40",
			required: false,
			useDefaultForNull: true
		},
	},

	computed: {
		normalizedSize() {
			if (
				typeof this.size == 'string'
				&& this.size.includes(':')
			) {
				return this.size.split(':');
			}

			return [this.size, this.size];
		},

		sizeWidth() {
			return this.normalizedSize[0];
		},

		sizeHeight() {
			return this.normalizedSize[1];
		},
	},
}
</script>

<style lang="scss" scoped>
.image-icon {
	color: var(--primary);
	fill: var(--primary) !important;
}
</style>
